import React from "react";

const About = () => {
    return (
        <section class="blurb">
            <h2>We're all pickie...</h2>
            <p>Pickie is a highly intelligent decision making tool that allows you to set your criteria, evaluate every option, and rank them in real time giving you an instant answer to some of your toughest decisions. No matter how pickie you think you are.</p>
        </section>
    )
};

export default About;