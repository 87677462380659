import React from "react";
import logo from "../images/logo.svg";
import LandscapeImg from "../components/Static/Landscape";

const Nav = () => {
    return (
        <header>
            <nav className="navbar">
                <img src={logo} alt="logo"/>  
            </nav>
            <div className="header featured-header">
                <h1>Find out what you <span className="highlight">really</span> want.</h1>
                <div className="landscape-visual-interest">
                    <LandscapeImg />
                    <div className="visual-interest-sun"></div>
                    <div className="visual-interest-red"></div>
                </div>
            </div>
        </header>
    )
};

export default Nav;