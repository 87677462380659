import React from "react";
import Nav from "./components/Nav";
import About from "./components/About";
import Features from "./components/Features";
import SignUpForm from "./components/SignUpForm";
import Footer from "./components/Footer";

function App() {
  return (
    <>
        <Nav />

        <About />

        {/* <Features /> */}

        <SignUpForm />

        <Footer />
    </>
  );
}

export default App;
