import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// import Bootstrap from 'bootstrap';

// stylesheet import
import '../src/sass/style.scss';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
