import React from "react";
// import $ from "jquery";



class SignUpForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            house: '1',
            car: '2',
            career: '4',
            project: '8', 
            trip: '16',
            other: '32',
            email: '',
            fname: '',
            lname: '',
            b_3c90a5e6eafb4c813b7527e8b_19836a0985: '',
        };
    }

    render() {
        return (
            <section className="p-5 form-cta-container">
                {/* <h2>When you know...</h2>
                <p></p> */}
                <a target="_blank" rel="noreferrer" href="https://900bc008.sibforms.com/serve/MUIEAFxr3WITxU1gyfsXGM5c8ogdTVh748PF00_PntEkA-PDV_aUHGhK8QvDi7v2afQ1q09GBrBQ498-TTcy8WabUloasVUZMjWkV2Jc1YFtpv5yn-5Ku2NwMFXlqS0RDfbKU1oBioE1BWFWRI-D5pQF3yzdUnZWKrsW-qqraNKME_oAOXOCvwwctbUfQ_HltxmGTO5NKkJhO7kJ" className="btn btn-primary">Get Notified When We Launch!</a>
            </section>
        )
    }
};

export default SignUpForm;