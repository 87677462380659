import React from "react";

const Footer = () => {
    return (
        <footer>
            <div className="copyright">
                <p className="mb-0">&copy; PICKIE 2021</p>
            </div>
        </footer>
    )
};

export default Footer;